import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IdentityService } from 'app/api/sidc-services/identity';
import { ItemMasterDataNotificationsService } from 'app/api/sidc-services/sap-inventory-management';
import { InterComponentService } from 'app/common/services/inter-component.service';

import { NotificationsService } from 'app/layout/components/navbar/navbar-notification/notifications.service';
import { environment } from 'environments/environment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Subject, timer } from 'rxjs';
import { delayWhen, retryWhen, take, takeUntil } from 'rxjs/operators';

// Interface
interface notification {
  messages: [];
  systemMessages: [];
  system: Boolean;
}

@Component({
  selector: 'app-navbar-notification',
  styleUrls: ['./navbar-notification.component.scss'],
  templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit {
  // Public
  public notifications: notification;
  public isLoading:boolean = false;
  public userId:string;
  public retrievedNotifications:any[]=[];
  public notificationsToDisplay:any[]=[];
  public isChecker:boolean=false;
  public isApprover:boolean=false;
  public isEvaluator:boolean=false;
  public pendingDescription:string="New";
  public unreadNotificationsCount:number=0;
  private unsubscribeAll: Subject<any>;
  public updateRequest = {
    id: 0,
    isRead: false,
    isDone: false,
    isChecked: false,
    isEvaluated: false,
    evaluationId: null
  }

  @BlockUI() blockUI: NgBlockUI;
  /**
   *
   * @param {NotificationsService} _notificationsService
   */
  constructor(
    private _notificationsService: NotificationsService, 
    private _itemMasterDataNotificationsService: ItemMasterDataNotificationsService,
    private toastr: ToastrService,
    private router: Router,
    private datePipe: DatePipe,
    private interComponentService: InterComponentService    
    ) {
    this.unsubscribeAll = new Subject();
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.userId = currentUser?.id;

    if (this.userId){
      this.getNotifications(this.userId);
    }    

    this._notificationsService.onApiDataChange.subscribe(res => {
      this.notifications = res; 
    });
  }

  onNotificationClick(itemcode,branchcode,id){
    sessionStorage.setItem("notificationBranchCode", branchcode);
    this.interComponentService.updateBranchCode(branchcode);
    this.interComponentService.updateIsNotificationClick(true);

    this.updateRequest = {
      id: id,
      isRead: true,
      isDone: false,
      isEvaluated : false,
      isChecked: false,
      evaluationId: 0
    }

    this._itemMasterDataNotificationsService.apiVversionSapHanaInventoryItemMasterDataNotificationsPut(environment.apiVersion, this.updateRequest).pipe(takeUntil(this.unsubscribeAll))
    .subscribe(
      (response) => {
        this.getNotifications(this.userId);      
      },
      (httpError: HttpErrorResponse) => {
        this.blockUI.stop();
        this.toastr.error("", httpError.error.Message, {
          timeOut: 3000,
          positionClass: "toast-bottom-center",
          toastClass: "toast ngx-toastr",
        });
      }
    );
    
    setTimeout(() => {   
      sessionStorage.setItem("isNotificationClick", "true"); 
      const domain = window.location.origin;
      window.location.href = domain + '/application/inventory/sap/item-master-data/' + itemcode;
    }, 300)
    
  }

  checkUserIdRole(){
    const userString = localStorage.getItem("currentUser");
    const user = JSON.parse(userString);
    user.roles.forEach((a) => {
      if(a === 'PurchasingSupervisor'){
        this.isChecker = true;
        this.pendingDescription = "For Checking";
      }else if(a === 'MasterdataCustodian'){
        this.isApprover = true;
        this.pendingDescription = "For Approval";
      }else if(a === 'DataCustodian'){
        this.isEvaluator = true;
        this.pendingDescription = "For Evaluation";
      }
    })
  }

  getNotifications(passedUserId){
    this.isLoading = true;

    this.userId = this.userId ? this.userId : passedUserId;
    this.checkUserIdRole();

    this._itemMasterDataNotificationsService.apiVversionSapHanaInventoryItemMasterDataNotificationsUserIdGet(this.userId, environment.apiVersion)
    .pipe(retryWhen((errors) => errors.pipe(delayWhen(() => timer(1000)),take(5))))
    .subscribe(
      (response) => {
        this.retrievedNotifications = response.data.map(a => {
          a.sentOn = this.datePipe.transform(a.sentOn, "yyyy-MM-dd HH:mm");
          return a;
        });
        
        if (this.isChecker){
          this.notificationsToDisplay = this.retrievedNotifications.filter(a => a.isChecked == false);
        }else if(this.isApprover){
          this.notificationsToDisplay = this.retrievedNotifications.filter(a => a.isDone == false);
        }else if (this.isEvaluator){
          this.notificationsToDisplay = this.retrievedNotifications.filter(a => a.isEvaluated == false);
        }             

        let unread = this.retrievedNotifications.filter(a => a.isRead == false);        
        this.unreadNotificationsCount = this.notificationsToDisplay.length;
        setTimeout(() => { this.isLoading = false; }, 500);
      },
      (httpError: HttpErrorResponse) => {
        this.toastr.error("", httpError.error.Message, {
          timeOut: 3000,
          positionClass: "toast-bottom-center",
          toastClass: "toast ngx-toastr",
        });
      }
    );
  }

  ngAfterContentInit(){
    if (this.userId){
      this.checkUserIdRole();
      this.getNotifications(this.userId);
    }    
  }
}
