<!--Notification Bell-->
<li ngbDropdown class="nav-item dropdown-notification mr-25">
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown"
    ><i class="ficon" data-feather="bell"></i
    ><span *ngIf="!isLoading" class="badge badge-pill badge-danger badge-up">{{unreadNotificationsCount}}</span>
    <span *ngIf="isLoading" class="badge badge-pill badge-danger badge-up"><i data-feather="more-horizontal"></i></span>
    </a
  >
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right"
  >
    <!-- Notifications header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <a class="retrieve-notifications" [class.rotate]="isLoading" (click)="getNotifications('')"><i class="ficon" data-feather="refresh-cw"></i></a>
        <div class="badge badge-pill badge-light-primary ml-1">          
          {{unreadNotificationsCount}} {{pendingDescription}}
        </div>
      </div>
    </li>
    <!--/ Notifications header -->

    <!-- Notifications content -->
    <li class="scrollable-container media-list" [perfectScrollbar]>
      
      <a class="d-flex" *ngFor="let message of notificationsToDisplay">
        <div [class]="message.isRead === true ? 'media d-flex align-items-start read-notification' : 'media d-flex align-items-start unread-notification'">
          <div class="media-left" style="width:25%;font-size:xx-small;">
            {{message.sentOn}}
          </div>
          <div class="media-body" style="width:85%">
            <p class="media-heading" (click)="onNotificationClick(message.itemCode,message.branchCode,message.id)" [innerHTML]="message.message"></p>
            <small class="notification-text">Branch Code: {{message.branchCode}} | Item Code: {{message.itemCode}} | UOM Code: {{message.unitOfMeasureCode}}</small>
          </div>
        </div></a
      >
      <!-- <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">System Notifications</h6>
        <div class="custom-control custom-control-primary custom-switch">
          <input
            class="custom-control-input"
            id="systemNotification"
            type="checkbox"
            [checked]="notifications.system"
          />
          <label class="custom-control-label" for="systemNotification"></label>
        </div>
      </div> -->
      <!-- <a class="d-flex" href="javascript:void(0)" *ngFor="let systemMessage of notifications.systemMessages">
        <div class="media d-flex align-items-start">
          <div class="media-left">
            <div
              class="avatar"
              [ngClass]="{
                'bg-light-danger': systemMessage.icon === 'x',
                'bg-light-success': systemMessage.icon === 'check',
                'bg-light-warning': systemMessage.icon === 'alert-triangle'
              }"
            >
              <div class="avatar-content"><i class="avatar-icon" [data-feather]="systemMessage.icon"></i></div>
            </div>
          </div>
          <div class="media-body">
            <p class="media-heading" [innerHTML]="systemMessage.heading"></p>
            <small class="notification-text">{{ systemMessage.text }}</small>
          </div>
        </div></a
      > -->
    </li>
    <!--/ Notifications content -->

    <!-- Notifications footer -->
    <!-- <li class="dropdown-menu-footer">
      <a class="btn btn-primary btn-block" disabled href="javascript:void(0)">Read all notifications</a>
    </li> -->
    <!--/ Notifications footer -->
  </ul>
</li>

<!--Notification Bell-->