import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InterComponentService {
  private userBranchCodeSource = new BehaviorSubject<string>(undefined);  
  private isNotificationClickSource = new BehaviorSubject<boolean>(false);

  userBranchCode$ = this.userBranchCodeSource.asObservable();
  isNotificationClick$ = this.isNotificationClickSource.asObservable();

  constructor() { }

  updateBranchCode(branchCode: string): void {
    this.userBranchCodeSource.next(branchCode);
  }

  updateIsNotificationClick(isNotificationClick: boolean): void {
    this.isNotificationClickSource.next(isNotificationClick);
  }
}
