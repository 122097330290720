<!-- Menu header -->
<div class="navbar-header">
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item mr-auto">
      <!-- App Branding -->
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" />
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appCode }}</h2>
      </a>
    </li>

    <!-- Menu Toggler -->
    <li class="nav-item nav-toggle">
      <a class="nav-link modern-nav-toggle d-none d-xl-block pr-0" (click)="toggleSidebarCollapsible()">
        <i
          [ngClass]="isCollapsed === true ? 'icon-circle' : 'icon-disc'"
          class="toggle-icon feather font-medium-4 collapse-toggle-icon text-primary"
        ></i>
      </a>
      <a class="nav-link modern-nav-toggle d-block d-xl-none pr-0" (click)="toggleSidebar()">
        <i data-feather="x" class="font-medium-4 text-primary toggle-icon"></i>
      </a>
    </li>
  </ul>
</div>
<!--/ Menu header -->

<!-- Navbar shadow -->
<div class="shadow-bottom" [ngClass]="{ 'd-block': isScrolled }"></div>

<!-- Main menu -->
<div class="main-menu-content" [perfectScrollbar] (scroll)="onSidebarScroll()">
  <ul class="navigation navigation-main" layout="vertical" core-menu></ul>
  <div class="info-container">
    <div class="static-text-box-header">
      <strong class="light-bold">Information</strong>
        </div>
        <div class="static-text-box">
          <span><b class="light-bold">Username: </b>{{ this.userValue.username }}</span> <br/>
          <span><b class="light-bold">Name: </b>{{ this.userValue.fullname }} </span> <br/>
          <span *ngIf="this.userValue.branchCode"><b class="light-bold">Branch: </b>{{ this.userValue.branchCode[0] }}
            <span *ngIf="this.userValue.branchCode.length > 1" placement="top" [ngbTooltip]="moreBranches">
            <i data-feather="more-horizontal"></i></span><br/>
          </span> 
          <span *ngIf="this.userValue.warehouse && this.userValue.warehouse.length > 0"><b class="light-bold">Warehouse: </b>{{ this.userValue.warehouse[0] }}
            <span *ngIf="this.userValue.warehouse && this.userValue.warehouse.length > 1" placement="top" [ngbTooltip]="moreWarehouses">
              <i data-feather="more-horizontal"></i></span>
          </span>
        </div>
  </div>
</div>
<!--/ Main menu -->
